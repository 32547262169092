import React, { useState, useEffect } from 'react';
import {
    useNotify,
    useRefresh,
    useDataProvider,
    useGetIdentity,
} from 'react-admin';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    TextField,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { formatDate } from '../../../helpers/orderHelper';

const CommentsTab = ({ record, editable }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [comments, setComments] = useState([]);
    const [commentToDelete, setCommentToDelete] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const { identity, isLoading: identityLoading } = useGetIdentity();

    const orderId = record.id;

    useEffect(() => {
        dataProvider.getList('comments', {
            filter: { orderId },
            sort: { field: 'createdAt', order: 'DESC' },
            pagination: { page: 1, perPage: Number.MAX_SAFE_INTEGER },
        })
            .then(({ data }) => setComments(data))
            .catch(() => notify('Error al obtener comentarios', 'warning'));
    }, [orderId, dataProvider, notify]);

    const handleCreateComment = () => {
        if (!commentText.trim()) return;

        const newComment = {
            message: commentText,
            orderId,
        };

        dataProvider.create('comments', { data: newComment })
            .then(() => {
                notify('Comentario creado correctamente', 'info');
                refresh();
            })
            .catch(() => notify('Error al crear el comentario', 'warning'));
    };

    const handleRequestDelete = (comment) => {
        setCommentToDelete(comment);
        setConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        if (!commentToDelete) return;
        dataProvider.delete('comments', { id: commentToDelete.id, previousData: commentToDelete })
            .then(() => {
                notify('Comentario eliminado correctamente', 'info');
                refresh();
            })
            .catch(() => {
                notify('Error al eliminar el comentario', 'warning');
                setConfirmDialogOpen(false);
                setCommentToDelete(null);
            });
    };

    const handleCancelComment = () => {
        setModalOpen(false);
        setCommentText('');
    };

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
        setCommentToDelete(null);
    };

    if (identityLoading) return null;

    return (
        <>
            <List padding={2}>
                {
                    comments?.length ? comments.map((comment) => (
                        <ListItem key={comment.id} divider style={{ padding: 0 }}>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Box>
                                    <Typography variant="body1">
                                        {`${comment.createdBy.name}: ${comment.message}`}
                                    </Typography>
                                    <Typography variant="caption" display="block">
                                        {`Fecha de creación: ${formatDate(comment.createdAt)}`}
                                    </Typography>
                                </Box>
                                {
                                    editable
                                    && identity
                                    && comment.createdBy.userId === identity.id && (
                                        <IconButton
                                            onClick={() => handleRequestDelete(comment)}
                                            color="secondary"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )
                                }
                            </Box>
                        </ListItem>
                    )) : (
                        <Typography align="center">
                            Esta orden aún no tiene comentarios
                        </Typography>
                    )
                }

                {editable && (
                    <Button variant="outlined" color="primary" onClick={() => setModalOpen(true)}>
                        + CREATE
                    </Button>
                )}
            </List>
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)} fullWidth>
                <DialogTitle>Nuevo Comentario</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Comentario"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelComment} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleCreateComment} color="primary">
                        Guardar Cambios
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmDialogOpen} onClose={handleCancelDelete}>
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>¿Está seguro de que desea eliminar este comentario?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CommentsTab;
