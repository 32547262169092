import React from 'react';
import { Edit, FormTab, TabbedForm } from 'react-admin';

import OrderEditToolbar from './OrderEditToolbar';
import OrderTitle from './OrderTitle';
import CommentsTab from './Tabs/CommentsTab';
import DetailsTab from './Tabs/DetailsTab';
import MainFieldsTab from './Tabs/MainFieldsTab';
import ShippingDataTab from './Tabs/ShippingDataTab';

const OrderEdit = (props) => (
    <Edit title={<OrderTitle />} {...props}>
        <TabbedForm toolbar={<OrderEditToolbar />}>
            <FormTab label="Main">
                <MainFieldsTab editable />
            </FormTab>
            <FormTab label="Details">
                <DetailsTab editable />
            </FormTab>
            <FormTab label="Shipping Data">
                <ShippingDataTab editable />
            </FormTab>
            <FormTab label="Notes">
                <CommentsTab editable />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default OrderEdit;
