import * as React from 'react';
import { Filter, SelectInput, TextInput } from 'react-admin';

import choices from './choices';

export default (props) => (
    <Filter {...props}>
        <TextInput style={{ width: '250px' }} label="Search by code" source="code" alwaysOn />
        <TextInput style={{ width: '250px' }} label="Search by buyer email" source="buyerData.email" alwaysOn />
        <TextInput style={{ width: '250px' }} label="Search by product code" source="products.item.productShadow.code" alwaysOn />
        <TextInput style={{ width: '250px' }} label="Search by product name" source="products.item.productShadow.name" alwaysOn />
        <TextInput style={{ width: '250px' }} label="Search by N° Factura" source="billId" />
        <TextInput style={{ width: '250px' }} label="Search by product serial number" source="products.serialNumber" />
        <SelectInput choices={choices.status} label="Search by status" source="status" />
    </Filter>
);
