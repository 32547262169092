import * as React from 'react';
import { useGetOne } from 'react-admin';
import { LinearProgress, Typography } from '@material-ui/core';

const LastCommentField = ({ record }) => {
    if (!record?.comments || record.comments.length === 0) return null;
    const lastComment = record.comments[record.comments.length - 1];
    const { data, isLoading, error } = useGetOne('comments', lastComment);

    if (isLoading) return <LinearProgress />;
    if (error || !data) return null;
    const truncatedMessage = data.message.length > 20 ? `${data.message.substring(0, 20)}...` : data.message;

    return (
        <Typography variant="body2" align="center">
            {truncatedMessage}
        </Typography>
    );
};

export default LastCommentField;
