import React, { useEffect, useState } from 'react';
import {
    AdminContext, AdminUI, Resource, useCheckAuth, useTimeout,
} from 'react-admin';
import {
    Memory,
    Person,
    VideogameAsset,
    ViewCarousel,
    FindInPage,
    Image,
    AttachMoney,
    AccountBalance,
} from '@material-ui/icons';
import authProvider from './authProvider';
import dataProvider from './dataProvider/dataProvider';
import ProductShow from './resources/Products/ProductShow';
import ProductCreate from './resources/Products/ProductCreate';
import ProductList from './resources/Products/ProductList';
import ProductEdit from './resources/Products/ProductEdit';
import GameList from './resources/Games/GameList';
import GameShow from './resources/Games/GameShow';
import GameCreate from './resources/Games/GameCreate';
import GameEdit from './resources/Games/GameEdit';
import UserList from './resources/Users/UserList';
import UserCreate from './resources/Users/UserCreate';
import UserShow from './resources/Users/UserShow';
import UserEdit from './resources/Users/UserEdit';
import SlideList from './resources/Slides/SlideList';
import SlideShow from './resources/Slides/SlideShow';
import SlideCreate from './resources/Slides/SlideCreate';
import SlideEdit from './resources/Slides/SlideEdit';
import BrandImageList from './resources/BrandImages/BrandImageList';
import BrandImageShow from './resources/BrandImages/BrandImageShow';
import BrandImageCreate from './resources/BrandImages/BrandImageCreate';
import BrandImageEdit from './resources/BrandImages/BrandImageEdit';
import CustomLoginPage from './CustomLoginPage';
import OrderShow from './resources/Orders/OrderShow';
import OrderList from './resources/Orders/OrderList';
import OrderEdit from './resources/Orders/OrderEdit';
import ExchangeRatesList from './resources/ExchangeRates/ExchangeRatesList';
import Dashboard from './dashboard/Dashboard';
import ConfigList from './resources/Config/ConfigList';
import ConfigEdit from './resources/Config/ConfigEdit';

import CouponList from './resources/Coupons/CouponList';
import CouponShow from './resources/Coupons/CouponShow';
import CouponCreate from './resources/Coupons/CouponCreate';
import CouponEdit from './resources/Coupons/CouponEdit';
import generateTheme from './theme';

const isAdmin = (role) => role === 'admin';
const isAdminOrAdministrative = (role) => role === 'admin' || role === 'administrativo';

// La solución se implementa de esta manera debido a una limitación
// de React Admin asociada al asincronismo del check auth.
// Al requerir la información sobre el rol / permiso en el login, como el checkauth
// es asincrónico, todavía no tenemos seteado el valor y eso produce un error al consultarlo
// para mostrar o no las entradas de menú.
// Se hizo una solución temporal a la espera de una definitiva por parte de React Admin

const App = () => (
    <AdminContext
        disableTelemetry
        authProvider={authProvider}
        dataProvider={dataProvider(process.env.REACT_APP_API_URL)}
    >
        <AuthOnlyAdminUI
            loginPage={CustomLoginPage}
            dashboard={Dashboard}
        />
    </AdminContext>
);

const AuthOnlyAdminUI = (props) => {
    const checkAuth = useCheckAuth();
    const [authChecked, setAuthChecked] = useState(false);
    const oneSecondHasPassed = useTimeout(1000);
    const {
        loginPage,
        dashboard,
    } = props;
    const isBlueColorActive = process.env.REACT_APP_BLUE_COLOR === 'true';
    const theme = generateTheme(isBlueColorActive);

    useEffect(() => {
        checkAuth()
            .finally(() => {
                setAuthChecked(true);
            });
    }, [checkAuth, setAuthChecked]);

    if (authChecked) {
        return (
            <AdminUI
                theme={theme}
                loginPage={loginPage}
                dashboard={dashboard}
            >
                {(role) => [
                    <Resource
                        name="products"
                        list={ProductList}
                        show={ProductShow}
                        create={isAdminOrAdministrative(role) ? ProductCreate : null}
                        edit={isAdminOrAdministrative(role) ? ProductEdit : null}
                        icon={Memory}
                    />,
                    <Resource
                        name="games"
                        list={GameList}
                        show={GameShow}
                        create={isAdminOrAdministrative(role) ? GameCreate : null}
                        edit={isAdminOrAdministrative(role) ? GameEdit : null}
                        icon={VideogameAsset}
                    />,
                    isAdminOrAdministrative(role)
                        ? (
                            <Resource
                                name="slides"
                                list={SlideList}
                                show={SlideShow}
                                create={SlideCreate}
                                edit={SlideEdit}
                                icon={ViewCarousel}
                            />
                        )
                        : null,
                    isAdminOrAdministrative(role) ? (
                        <Resource
                            name="brandImages"
                            options={{ label: 'Brand Images' }}
                            list={BrandImageList}
                            show={BrandImageShow}
                            create={BrandImageCreate}
                            edit={BrandImageEdit}
                            icon={Image}
                        />
                    ) : null,
                    <Resource
                        name="users"
                        list={UserList}
                        show={UserShow}
                        create={isAdmin(role) ? UserCreate : null}
                        edit={isAdmin(role) ? UserEdit : null}
                        icon={Person}
                    />,
                    <Resource
                        name="orders"
                        list={OrderList}
                        show={OrderShow}
                        edit={isAdminOrAdministrative(role) ? OrderEdit : null}
                        icon={FindInPage}
                    />,
                    isAdminOrAdministrative(role) ? (
                        <Resource
                            name="exchangeRates"
                            list={ExchangeRatesList}
                            icon={AttachMoney}
                            options={{ label: 'Exchange Rates' }}
                        />
                    ) : null,
                    isAdminOrAdministrative(role) ? (
                        <Resource
                            name="configs"
                            options={{ label: 'Configurations' }}
                            list={ConfigList}
                            edit={ConfigEdit}
                            icon={AccountBalance}
                        />
                    ) : null,
                    <Resource
                        name="coupons"
                        list={CouponList}
                        show={CouponShow}
                        create={isAdmin(role) ? CouponCreate : null}
                        edit={isAdmin(role) ? CouponEdit : null}
                        icon={Person}
                    />,
                    <Resource name="comments" />,
                ]}
            </AdminUI>
        );
    }

    if (oneSecondHasPassed) {
        const {
            loading: LoadingPage,
        } = props;
        return <LoadingPage theme={theme} />;
    }

    return null;
};

export default App;
